import React from 'react';
import {Col} from 'antd';
import Gallery from 'components/Gallery';
import img1 from 'images/photo/1.jpg';
import img2 from 'images/photo/2.jpg';
import img3 from 'images/photo/3.jpg';
import img4 from 'images/photo/4.jpg';
import img5 from 'images/photo/5.jpg';
import img6 from 'images/photo/6.jpg';
import styles from './rules.module.scss';
import PublicImagesGallery from 'components/PublicImagesGallery';

const galleryImages = [
    {
        path: `${img1}`,
    },
    {
        path: `${img2}`,
    },
    {
        path: `${img3}`,
    },
    {
        path: `${img4}`,
    },
    {
        path: `${img5}`,
    },
    {
        path: `${img6}`,
    },
];
const StockRules: React.FC = (): JSX.Element => {
    return (
        <>
            <Col lg={24}>
                <h2 className={styles.rules_title}>Как принять участие</h2>
            </Col>
            <Col lg={12}>
                <div className={styles.list_rules}>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>01</span>
                        <p>Ознакомьтесь со списком детей, которым Вы можете выбрать подарок.</p>
                    </div>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>02</span>{' '}
                        <p>
                            Нажмите кнопку “Подарить” и введите свои данные. Далее организатор свяжется с Вами для
                            подтверждения брони.
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>03</span>{' '}
                        <p>
                            Купите или закажите подарок и сладости для выбранного ребенка в интернет магазине и
                            отправьте нам до 10 декабря (в прозрачной упаковке или подарочном пакете).
                        </p>
                    </div>
                </div>
            </Col>
            <Col lg={12}>
                <div className={styles.list_rules}>
                    <div className={styles.rules}>
                        <p>
                            Фото и отчет можно найти после 28 декабря на сайте, напротив своего ребенка в графе отчет.
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            Каждый год не все дети получают подарок  и сладости, а они верят и ждут. Для помощи сбора
                            этой акции, Вы можете перечислить денежные средства на карту ПриватБанка
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            <strong>№ 5363 5420 1719 9317</strong> Ткаченко Валентина Валентиновна
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            <PublicImagesGallery photos={galleryImages}>
                                Фотографии
                            </PublicImagesGallery>
                            {' '} и{' '}
                            <a
                                href="https://www.youtube.com/channel/UCQuQPnQVqSwly5nJywAGQDQ"
                                target="_blank"
                                className={styles.link}
                            >
                                Видео
                            </a>{' '}
                            с прошлогоднего праздника Дня Святого Николая.
                        </p>
                    </div>
                </div>
            </Col>
        </>
    );
};
export default StockRules;
