import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon} from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';

import {getPathToPhoto} from 'utils/index';
import * as statuses from 'constants/statuses';
import * as socialStatuses from 'constants/socialStatuses';
import Gallery from 'components/Gallery';

import {Gift, PersonWhoBookedGift} from 'types/index';
import bell from 'images/icon/Bell.png';
import styles from './card.module.scss';
import PostedImagesGallery from 'components/PostedImagesGallery';

interface GiftListItemProps {
    gift: Gift;
    isAdmin?: boolean;
    onBookItem?: (data: Gift) => void;
    onEditItem?: (data: Gift) => void;
    onDeleteItem?: (data: Gift) => void;
}

const propTypes = {
    gift: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    onBookItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
};

const defaultProps = {
    isAdmin: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBookItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onEditItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteItem: () => {},
};

const GiftItem = ({gift, isAdmin, onBookItem, onEditItem, onDeleteItem}: GiftListItemProps): JSX.Element | null => {
    const {description, status, attachments} = gift;
    const childBirthDay = gift.child.birthDay ? moment(gift.child.birthDay).format('DD.MM.YYYY') : null;
    const giftAvatars = attachments.avatars || [];
    const giftWishes = attachments.wishes || [];
    const galleryImages = [...giftAvatars, ...giftWishes];
    const pathToAvatar = getPathToPhoto(gift.attachments?.avatars[0]?.path);
    const previewImage = isEmpty(attachments.avatars) ? (
        <img
            src="https://cdn.imgbin.com/6/1/14/imgbin-computer-icons-child-avatar-boy-child-pnauWRgGZvqQamBJJ2jFQePWy.jpg"
            alt="avatar"
        />
    ) : (
        <PostedImagesGallery photos={galleryImages}>
            <img src={pathToAvatar} alt="avatar" />
        </PostedImagesGallery>
    );

    const handleBookGift = (): void => {
        if (onBookItem) {
            onBookItem(gift);
        }
    };
    const handleEditGift = (): void => {
        if (onEditItem) {
            onEditItem(gift);
        }
    };
    const handleDeleteGift = (): void => {
        if (onDeleteItem) {
            onDeleteItem(gift);
        }
    };
    const bookedPersonData = (data: PersonWhoBookedGift) => {
        if (status !== statuses.GIFT_STATUS_FREE) {
            return (
                <div className="booked-person">
                    <div>{data.name}</div>
                    <div>{data.phone}</div>
                </div>
            );
        }
        return null;
    };

    // const calcDaysLeft = () => {
    //     const currentYear = moment().year();
    //     const childBirthDayInCurrentYear = moment(gift.child.birthDay).year(currentYear);
    //     const currentDay = moment().startOf('day');
    //     const leftDaysUntilChildBirthDay = childBirthDayInCurrentYear.diff(currentDay, 'days');
    //     if (leftDaysUntilChildBirthDay < 0 && !isAdmin) {
    //         return 'день рождения прошел';
    //     }
    //     return leftDaysUntilChildBirthDay;
    // };

    // eslint-disable-next-line no-shadow
    const getSocialStatus = (status: string) => {
        if (status === socialStatuses.DISABLED) {
            return `ребенок с инвалидностью ${gift.child?.diagnosis && `(${gift.child.diagnosis})`}`;
        }
        if (status === socialStatuses.ORPHAN) {
            return 'сироты';
        }
        if (status === socialStatuses.FATHER_DIED_ATO) {
            return 'отцы, погибшие в АТО (безвести пропали)';
        }
        if (status === socialStatuses.LARGE_FAMILY) {
            return 'многодетные семьи';
        }
        if (status === socialStatuses.LARGE_FAMILY_INCLUSION) {
            return 'многодетные семьи, где есть инклюзия';
        }
        if (status === socialStatuses.CANCER) {
            return 'онкобольные дети';
        }
        if (status === socialStatuses.MIGRANTS) {
            return 'переселенцы';
        }
        if (status === socialStatuses.ATO_FAMILY) {
            return 'семьи АТО';
        }
        return 'сложные жизненные обстоятельства';
    };

    return (
        <div className={styles.item}>
            <div className="photo">
                {previewImage}
                {/*{status !== statuses.GIFT_STATUS_DONE && (*/}
                {/*    <div className="days-left">*/}
                {/*        <img src={srcGift} alt="gift" />*/}
                {/*        {calcDaysLeft()}*/}
                {/*    </div>*/}
                {/*)}*/}
                {!gift.isApproved && isAdmin && <div className="not-approved">новая заявка</div>}
            </div>
            <div className="content">
                <div className="content__title">
                    {gift.child.name}
                    <div className="content__title_city">{gift.child.city} </div>
                    <div className="content__title_date">{childBirthDay && <>{childBirthDay} г.р.</>} </div>
                </div>
                <div className="content__description">{description}</div>
                <div className="content__type">{getSocialStatus(gift.child.socialStatus)}</div>
                {isAdmin ? (
                    <>
                        <div className="content__status">
                            {status === statuses.GIFT_STATUS_FREE && (
                                <div className="content__status_free">Свободен</div>
                            )}

                            {status === statuses.GIFT_STATUS_BOOKED && (
                                <div className="content__status_booked">Забронирован</div>
                            )}

                            {status === statuses.GIFT_STATUS_CONFIRMED && (
                                <div className="content__status_confirmed">Подтвержден</div>
                            )}

                            {status === statuses.GIFT_STATUS_DONE && (
                                <div className="content__status_done">Исполнен</div>
                            )}
                            {bookedPersonData(gift.bookedPerson)}
                        </div>
                        <div className="content__admin">
                            <Button className="custom-btn-edit" onClick={handleEditGift}>
                                Редактировать
                            </Button>
                            <Button className="custom-btn-remove" onClick={handleDeleteGift}>
                                <Icon type="delete" theme="filled" style={{fontSize: '18px'}} />
                            </Button>
                        </div>
                        <div className="content__request">
                            {status === statuses.GIFT_STATUS_DONE && attachments?.reports && (
                                <>
                                    <Gallery photos={attachments.reports}>Отчет</Gallery>
                                    {gift?.videoReportLink && (
                                        // eslint-disable-next-line react/jsx-no-target-blank
                                        <a className="youtube-link" href={`${gift.videoReportLink}`} target="_blank">
                                            <Icon type="youtube" theme="filled" style={{fontSize: '18px'}} />
                                        </a>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="content__create-at">
                            Заявка подана: {moment(gift.createdAt).format('DD.MM.YYYY HH:mm')}
                        </div>
                    </>
                ) : (
                    <div className="content__status">
                        {status === statuses.GIFT_STATUS_FREE && (
                            <Button onClick={handleBookGift} className="custom-btn custom-btn__free" type="primary">
                                Подарить
                            </Button>
                        )}

                        {status === statuses.GIFT_STATUS_BOOKED && (
                            <Button className="custom-btn custom-btn__booked" disabled={true}>
                                Забронирован
                            </Button>
                        )}

                        {status === statuses.GIFT_STATUS_CONFIRMED && (
                            <Button className="custom-btn custom-btn__confirmed" disabled={true}>
                                Подтвержден
                            </Button>
                        )}

                        {status === statuses.GIFT_STATUS_DONE && (
                            <div className="content__status_done-wrap">
                                <Button className="custom-btn custom-btn__done" disabled={true}>
                                    Исполнен
                                </Button>
                                <div className="reports-links">
                                    {attachments?.reports && (
                                        <Gallery photos={attachments.reports}>
                                            <img src={bell} alt="bell" />
                                            Отчет
                                        </Gallery>
                                    )}
                                    {gift?.videoReportLink && (
                                        // eslint-disable-next-line react/jsx-no-target-blank
                                        <a className="youtube-link" href={`${gift.videoReportLink}`} target="_blank">
                                            <Icon type="youtube" theme="filled" style={{fontSize: '18px'}} />
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

GiftItem.propTypes = propTypes;
GiftItem.defaultProps = defaultProps;

export default GiftItem;
