import React from 'react';
import {Col} from 'antd';
import girl from 'images/photo/girl.png';
import styles from './stockInformation.module.scss';

export const StockInformation: React.FC = (): JSX.Element => {
    return (
        <>
            <Col lg={12}>
                <h2 className={styles.title}>
                    Благотворительная Акция <br /> “Стань Волшебником”
                </h2>
                <p className={styles.text}>
                    Каждый год фонд “Доброта и доверие” поздравляет особенных деток с Днем Святого Николая!
                </p>
                <iframe
                    height="315"
                    src="https://www.youtube.com/embed/ybpap0h7vaU"
                    title="Доброта и доверие"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={styles.video}
                />
                <small className={styles.information_card}>
                    Ниже выложены фотографии писем ребятишек, которые верят в чудо и добро. Дети с разными жизненными
                    историями: инвалиды, сироты, онкобольные, но всех их объединяет вера. Вера в волшебство! Приглашаем
                    стать для кого-то феей или волшебником!
                </small>
            </Col>
            <Col lg={10} xs={22} offset={2}>
                <img className={styles.photo} src={girl} alt="girl" />
            </Col>
        </>
    );
};
export default StockInformation;
