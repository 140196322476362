import React from 'react';
import {Checkbox} from 'antd';
import 'moment/min/locales';

import {GIFT_STATUS_FREE} from 'constants/statuses';
import {SearchParams} from '../../types';
import MonthDropDown from '../common/MonthDropDown';

interface OwnProps {
    searchParams: SearchParams;
    onClearParams(params: any): void;
    onSearchFreeGifts(status?: string): void;
    onSearchByMonth(params: any): void;
}

const SearchForGuest = ({
    searchParams,
    onSearchByMonth,
    onSearchFreeGifts,
    onClearParams,
}: OwnProps): JSX.Element | null => {
    const handleChangeFreeStatus = (event: any) => {
        if (event.target.checked) {
            onSearchFreeGifts(GIFT_STATUS_FREE);
        } else {
            onSearchFreeGifts();
        }
    };

    const handleChangeMonth = (momentMonth: any): void => {
        onSearchByMonth({
            month: momentMonth,
        });
    };
    return (
        <div className="filter">
            <div className="filter__item">
                <Checkbox
                    id="freeStatusCheckbox"
                    checked={searchParams.status === GIFT_STATUS_FREE}
                    onChange={handleChangeFreeStatus}
                >
                    Показать только незабронированные пожелания
                </Checkbox>
            </div>
            {/*<div className="filter__item">*/}
            {/*    Показать дни рождения: <MonthDropDown value={searchParams.month} onChange={handleChangeMonth} />*/}
            {/*</div>*/}
            {/*<div onClick={onClearParams} className="filter__item">*/}
            {/*    <span className="filter__item_clear">Очистить фильтры</span>*/}
            {/*</div>*/}
        </div>
    );
};

export default SearchForGuest;
