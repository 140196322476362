import React from 'react';
import {Drawer} from 'antd';

import * as statuses from 'constants/statuses';
import * as socialStatuses from 'constants/socialStatuses';
import * as deliveryCarriers from 'constants/deliveryCarriers';

import {Gift} from 'types/index';

import Form from '../Form';

const Create = ({isAdmin, mode, onClose, onSubmit}: any): JSX.Element => {
    const initialValues: Gift = {
        _id: '',
        child: {
            name: '',
            diagnosis: '',
            socialStatus: socialStatuses.ORPHAN,
            birthDay: '',
            city: '',
        },
        parent: {
            name: '',
            phone: '',
            type: 'parent',
            socialNetworkLink: '',
        },
        bookedPerson: {
            name: '',
            phone: '',
            comment: '',
        },
        description: '',
        status: statuses.GIFT_STATUS_FREE,
        attachments: {
            avatars: [],
            wishes: [],
            documents: [],
            reports: [],
        },
        deliveryTo: {
            officeAddress: '',
            officeNumber: '',
            carrier: deliveryCarriers.NOVA_POSHTA,
        },
        videoReportLink: '',
        createdAt: '',
        isApproved: false,
    };

    return (
        <Drawer visible={true} onClose={onClose} width={720} title="Анкета на участие в проекте “Святой Николай”">
            <Form initialValues={initialValues} isAdmin={isAdmin} mode={mode} onClose={onClose} onSubmit={onSubmit} />
        </Drawer>
    );
};

export default Create;
