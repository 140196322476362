import React, {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'antd';
import ReactSnowFall from 'react-snowfall';
import useShowModalsActions from 'hooks/useShowModalsActions';
import {Gift} from 'types/index';

import {Link} from 'react-router-dom';
import * as actions from './actions';
import styles from './main.module.scss';

const Index = (): JSX.Element | null => {
    const {showCreateGiftModal} = useShowModalsActions();
    const dispatch = useDispatch();
    const showCreateGiftModalHandler = useCallback(() => {
        showCreateGiftModal((gift: Gift): void => {
            dispatch(actions.createGift(gift));
        });
    }, [dispatch, showCreateGiftModal]);
    return (
        <div className={styles.wrap}>
            <ReactSnowFall />
            <div className={styles.item}>
                <div className="description">
                    <div className="description__title">Осуществи мечту ребенка! Стань Святым Николаем!</div>
                    Каждый год, фонд “Доброта и доверие” поздравляет особенных деток с Днем Святого Николая!
                    <div className="description__button">
                        <Button onClick={showCreateGiftModalHandler} className="custom-btn custom-btn__request">
                            ПОДАТЬ ЗАЯВКУ
                        </Button>
                        <Link to="/gifts" className="ant-btn custom-btn custom-btn__action ant-btn-primary">
                            СТАТЬ ВОЛШЕБНИКОМ
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
