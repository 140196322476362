import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';

import {Gift} from 'types';
import useShowModalsActions from 'hooks/useShowModalsActions';

import GiftItem from './components/GiftItem';
import BreadCrumbs from './components/BreadCrumbs';
import SearchForAdmin from './components/SearchForAdmin';
import Pagination from './components/Pagination';
import * as actions from './actions/adminActions';
import {changePaginationPage, changeSearchParams, clearSearchParams} from './actions/commonActions';
import {getListGifts, getPagination, getSearch} from './selectors';

const GiftsForAdminRole = (): JSX.Element => {
    const gifts = useSelector(getListGifts);
    const searchParams = useSelector(getSearch);
    const pagination = useSelector(getPagination);
    const {showCreateGiftModal, showEditGiftModal, showDeleteGiftModal} = useShowModalsActions();
    const dispatch = useDispatch();
    const searchByMonthHandler = useCallback(
        data => {
            dispatch(changeSearchParams({month: data.month}));
        },
        [dispatch],
    );
    const refreshGiftsListHandler = useCallback(() => {
        dispatch(actions.refreshGiftsList(searchParams.status));
    }, [dispatch, searchParams]);
    const clearSearchParamsHandler = useCallback(() => {
        dispatch(clearSearchParams());
    }, [dispatch]);
    const searchByStatusHandler = useCallback(
        status => {
            dispatch(changeSearchParams({status}));
        },
        [dispatch],
    );
    const addGiftHandler = useCallback(() => {
        showCreateGiftModal((dataForCreate: Gift) => {
            dispatch(actions.createGift(dataForCreate));
        });
    }, [dispatch, showCreateGiftModal]);
    const showDeleteGiftModalHandler = useCallback(
        (gif: Gift) => {
            showDeleteGiftModal(gif, (): void => {
                dispatch(actions.deleteGift(gif));
            });
        },
        [dispatch, showDeleteGiftModal],
    );
    const showEditGiftModalHandler = useCallback(
        (gif: Gift) => {
            showEditGiftModal(gif, (editedGift: any): void => {
                dispatch(actions.editGift(editedGift));
            });
        },
        [dispatch, showEditGiftModal],
    );
    const changePaginationPageHandler = useCallback(
        (nextPage: number) => {
            dispatch(changePaginationPage(nextPage));
        },
        [dispatch],
    );
    useEffect(() => {
        dispatch(actions.fetchGifts({searchParams, pagination: {page: pagination.page, size: pagination.size}}));
    }, [dispatch, searchParams, pagination.page, pagination.size]);
    return (
        <div>
            <BreadCrumbs />
            <h1>Дети</h1>
            <SearchForAdmin
                searchParams={searchParams}
                onRefreshGiftsList={refreshGiftsListHandler}
                onAddNewGift={addGiftHandler}
                onSearchByMonth={searchByMonthHandler}
                onClearParams={clearSearchParamsHandler}
                onSearchByStatus={searchByStatusHandler}
            />
            <Row className="gift-list-row" type="flex">
                {gifts.map((gift: Gift) => (
                    <Col lg={6} md={12} xs={24} key={gift._id}>
                        <GiftItem
                            gift={gift}
                            isAdmin={true}
                            onEditItem={showEditGiftModalHandler}
                            onDeleteItem={showDeleteGiftModalHandler}
                        />
                    </Col>
                ))}
            </Row>

            {gifts.length > 0 && (
                <Pagination
                    allPages={pagination.pagesCount}
                    currentPage={pagination.page}
                    onPageChange={changePaginationPageHandler}
                />
            )}
        </div>
    );
};

export default GiftsForAdminRole;
