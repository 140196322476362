import {Gift, PersonWhoBookedGift} from 'types/index';

import * as modalNames from 'constants/modals';
import * as appActions from 'app/actions';
import {bookGiftByGuest, fetchListGiftsByGuest} from 'services/api';

import {SearchParams} from '../types';
import * as types from '../actionTypes';

interface FetchGiftsParams {
    searchParams: SearchParams;
    pagination: {page: number; size: number};
}

interface ShowBookGiftConfirmModalParams {
    gift: Gift;
    personWhoBooked: PersonWhoBookedGift;
    onConfirm(): void;
}

const showBookGiftConfirmModal = ({gift, personWhoBooked, onConfirm}: ShowBookGiftConfirmModalParams) => {
    return function(dispatch: any) {
        const params = {
            name: modalNames.GIFT_BOOKING_CONFIRM_MODAL,
            data: {
                gift,
                personWhoBooked,
            },
            onSubmit: onConfirm,
        };
        dispatch(appActions.showModal(params));
    };
};

const showBookGiftSuccessModal = (gift: Gift) => {
    return function(dispatch: any) {
        const params = {
            name: modalNames.BOOKING_SUCCESS_MODAL,
            data: {
                gift,
            },
        };
        dispatch(appActions.showModal(params));
    };
};

export function fetchGifts({searchParams, pagination}: FetchGiftsParams): any {
    return function(dispatch: any) {
        dispatch(appActions.showLoaderSpinner());
        fetchListGiftsByGuest({
            page: pagination.page,
            size: pagination.size,
            status: searchParams.status,
        })
            .then(response => {
                dispatch({
                    type: types.LIST_GIFTS_RECEIVED,
                    payload: {
                        gifts: response.data.items,
                        pagination: {page: pagination.page, size: pagination.size, pagesCount: response.data.pages},
                    },
                });
            })
            .catch(error => {
                console.warn('Error on fetch gifts for guest role: ', error);
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));
    };
}

export function bookGift(gift: Gift, personWhoBooked: PersonWhoBookedGift): any {
    return function(dispatch: any) {
        const continueBookGift = (): void => {
            dispatch(appActions.showLoaderSpinner());

            bookGiftByGuest(gift, personWhoBooked)
                .then(response => {
                    dispatch({
                        type: types.SELECTED_GIFT_BOOKED,
                        payload: response.data,
                    });
                    dispatch(showBookGiftSuccessModal(response.data));
                })
                .catch(error => {
                    console.warn('Error on fetch gifts for guest role: ', error);
                })
                .finally(() => dispatch(appActions.hideLoaderSpinner()));
        };

        const confirmParams = {
            onConfirm: continueBookGift,
            gift,
            personWhoBooked,
        };

        dispatch(showBookGiftConfirmModal(confirmParams));
    };
}
