import React from 'react';
import {Row} from 'antd';
import styles from './information.module.scss';
import StockInformation from './components/StockInformation';
import StockRules from './components/StockRules';

export const Information = (): JSX.Element | null => {
    return (
        <>
            <Row type="flex" align="middle">
                <StockInformation />
            </Row>
            <div className={styles.rules_wrapper}>
                <Row type="flex">
                    <StockRules />
                </Row>
            </div>

            <h2 className={styles.title}>Дети</h2>
        </>
    );
};
