import React, {useCallback} from 'react';
import {Layout, Menu} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import * as appActions from 'app/actions';
import {isUserLoggedIn} from 'app/reducers/commonData/selectors';
import DDlogoSm from 'images/icon/DID_logo_mobile.png';
import DDlogoLg from 'images/icon/DID_logo_footer.png';
import LoaderSpinner from 'components/LoaderSpinner';

import styles from './header.module.scss';

const Header = (): JSX.Element => {
    const facebookLink = (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className="social-link" href="https://www.facebook.com/groups/197762237039265" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <rect x="8.40002" y="2.40002" width="12" height="21.6" fill="white" />
                    <path
                        d="M20.7658 0H3.23417C1.44809 0 0.00012207 1.44791 0.00012207 3.23405V20.7658C0.00012207 22.552 1.44803 23.9999 3.23417 23.9999H11.8807L11.8954 15.4236H9.66733C9.37778 15.4236 9.14276 15.1895 9.14164 14.8999L9.13096 12.1354C9.12984 11.8443 9.36556 11.6077 9.65671 11.6077H11.8808V8.93651C11.8808 5.83661 13.774 4.14869 16.5393 4.14869H18.8084C19.0988 4.14869 19.3342 4.38406 19.3342 4.67444V7.00547C19.3342 7.29573 19.0989 7.53104 18.8087 7.53122L17.4162 7.53187C15.9123 7.53187 15.6211 8.24648 15.6211 9.29522V11.6078H18.9256C19.2405 11.6078 19.4848 11.8827 19.4477 12.1954L19.12 14.9599C19.0887 15.2244 18.8643 15.4237 18.598 15.4237H15.6359L15.6211 24H20.766C22.552 24 23.9999 22.5521 23.9999 20.766V3.23405C23.9999 1.44791 22.552 0 20.7658 0Z"
                        fill="#3A559F"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </a>
    );
    const viberLink = (
        <a className="social-link" href="viber://chat/?number=%2B380972397270">
            <svg
                className="icon icon-viber"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0Z"
                    fill="#7D3DAF"
                />
                <path
                    d="M19.5903 7.73617L19.5856 7.71721C19.2022 6.16735 17.4738 4.50426 15.8866 4.15827L15.8687 4.15458C13.3014 3.66482 10.6982 3.66482 8.13146 4.15458L8.11303 4.15827C6.52631 4.50426 4.79792 6.16735 4.41401 7.71721L4.40979 7.73617C3.93583 9.90061 3.93583 12.0956 4.40979 14.26L4.41401 14.279C4.65695 15.2597 5.43827 16.2855 6.3814 16.9942C7.20002 17.6094 7.90923 18.4918 7.90923 19.5158C7.90923 20.142 8.67231 20.4495 9.10625 19.9977L10.0677 18.9983C10.5701 18.476 11.2754 18.2087 12.0001 18.2087C13.2924 18.2087 14.5853 18.0865 15.8687 17.8417L15.8866 17.838C17.4738 17.492 19.2022 15.8289 19.5856 14.279L19.5903 14.2601C20.0643 12.0956 20.0643 9.90061 19.5903 7.73617ZM18.2032 13.9462C17.9473 14.9573 16.6349 16.2143 15.5922 16.4466C14.2271 16.7061 12.8512 16.8171 11.4768 16.779C11.4494 16.7783 11.4231 16.7889 11.4041 16.8085C11.2093 17.0084 10.127 18.1194 10.1242 18.1223C10.1241 18.1224 10.1242 18.1223 10.1241 18.1224L8.76289 19.5194C8.66336 19.6232 8.48852 19.5526 8.48852 19.4094V16.5434C8.48852 16.496 8.45471 16.4558 8.40821 16.4467C8.40794 16.4466 8.40768 16.4466 8.40742 16.4465C7.3647 16.2143 6.05287 14.9572 5.7964 13.9461C5.36983 11.9897 5.36983 10.0064 5.7964 8.04999C6.05287 7.03886 7.3647 5.78181 8.40742 5.54956C10.7915 5.09614 13.2087 5.09614 15.5922 5.54956C16.6354 5.78181 17.9473 7.03886 18.2032 8.04999C18.6302 10.0065 18.6302 11.9897 18.2032 13.9462Z"
                    fill="white"
                />
                <path
                    d="M14.2683 15.2691C14.108 15.2204 13.9552 15.1877 13.8133 15.1288C12.3432 14.5189 10.9902 13.732 9.91844 12.5258C9.30897 11.8398 8.83195 11.0654 8.42872 10.2459C8.2375 9.85725 8.07635 9.45338 7.91209 9.05214C7.76232 8.68624 7.98293 8.30823 8.21522 8.03254C8.43319 7.77381 8.71367 7.5758 9.01743 7.42987C9.25452 7.31596 9.48839 7.38163 9.66155 7.58259C10.0359 8.01706 10.3797 8.47375 10.6581 8.97736C10.8293 9.28712 10.7824 9.66577 10.4721 9.87657C10.3967 9.92781 10.3279 9.98796 10.2577 10.0458C10.1961 10.0965 10.1381 10.1478 10.0959 10.2165C10.0187 10.3421 10.015 10.4903 10.0647 10.6269C10.4473 11.6785 11.0923 12.4962 12.1507 12.9366C12.3201 13.0071 12.4902 13.0891 12.6853 13.0663C13.0121 13.0282 13.1179 12.6697 13.3468 12.4825C13.5706 12.2995 13.8567 12.2971 14.0977 12.4496C14.3389 12.6022 14.5727 12.7661 14.805 12.9316C15.0331 13.0942 15.2602 13.2531 15.4706 13.4384C15.6728 13.6166 15.7425 13.8503 15.6286 14.0921C15.4201 14.5348 15.1166 14.9032 14.6789 15.1384C14.5553 15.2047 14.4077 15.2261 14.2683 15.2691C14.108 15.2204 14.4077 15.2261 14.2683 15.2691Z"
                    fill="white"
                />
                <path
                    d="M12.004 6.92359C13.927 6.97746 15.5064 8.25364 15.8449 10.1548C15.9026 10.4787 15.9231 10.8099 15.9487 11.1387C15.9595 11.2769 15.8812 11.4083 15.732 11.4101C15.5778 11.412 15.5085 11.283 15.4985 11.1448C15.4787 10.8711 15.4649 10.5963 15.4272 10.3249C15.2282 8.89244 14.0859 7.70732 12.6601 7.45301C12.4455 7.41472 12.2259 7.40466 12.0086 7.38186C11.8712 7.36743 11.6912 7.35911 11.6608 7.18833C11.6352 7.04514 11.7561 6.93112 11.8924 6.9238C11.9295 6.92175 11.9668 6.92343 12.004 6.92359C13.927 6.97746 11.9668 6.92343 12.004 6.92359Z"
                    fill="white"
                />
                <path
                    d="M14.9265 10.7119C14.9233 10.7359 14.9216 10.7924 14.9076 10.8456C14.8565 11.0386 14.5638 11.0628 14.4964 10.8679C14.4765 10.8101 14.4735 10.7443 14.4734 10.6821C14.4727 10.2748 14.3841 9.86793 14.1788 9.51356C13.9676 9.14934 13.6451 8.84316 13.2667 8.6579C13.038 8.54588 12.7906 8.47626 12.5398 8.43477C12.4303 8.41665 12.3195 8.40564 12.2094 8.39032C12.0759 8.37178 12.0047 8.28673 12.011 8.15523C12.0169 8.032 12.107 7.94337 12.2412 7.95095C12.6826 7.97602 13.1088 8.07139 13.5011 8.27915C14.299 8.70166 14.7547 9.36858 14.8878 10.2591C14.8938 10.2994 14.9034 10.3394 14.9065 10.3799C14.914 10.4799 14.9188 10.58 14.9265 10.7119C14.9233 10.7359 14.9188 10.58 14.9265 10.7119Z"
                    fill="white"
                />
                <path
                    d="M13.7304 10.6654C13.5695 10.6683 13.4834 10.5792 13.4668 10.4317C13.4553 10.3289 13.4462 10.2246 13.4216 10.1246C13.3733 9.92751 13.2685 9.74488 13.1027 9.62413C13.0245 9.56709 12.9358 9.52554 12.8429 9.49873C12.7249 9.46466 12.6023 9.47404 12.4846 9.44518C12.3567 9.41384 12.286 9.31031 12.3061 9.19034C12.3244 9.08117 12.4306 8.99596 12.5499 9.0046C13.2954 9.05842 13.8283 9.44386 13.9043 10.3215C13.9097 10.3835 13.916 10.4489 13.9023 10.5082C13.8787 10.6096 13.8036 10.6604 13.7304 10.6654C13.5695 10.6683 13.8036 10.6604 13.7304 10.6654Z"
                    fill="white"
                />
            </svg>
        </a>
    );

    const instaLink = (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className="social-link" href="https://instagram.com/dobrota_ta_dovira?igshid=140j7zzmzvo77" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M1.50032 1.63305C-0.385679 3.59205 0.000321329 5.67305 0.000321329 11.995C0.000321329 17.245 -0.915679 22.508 3.87832 23.747C5.37532 24.132 18.6393 24.132 20.1343 23.745C22.1303 23.23 23.7543 21.611 23.9763 18.788C24.0073 18.394 24.0073 5.60305 23.9753 5.20105C23.7393 2.19405 21.8883 0.461046 19.4493 0.110046C18.8903 0.0290464 18.7783 0.00504639 15.9103 4.6391e-05C5.73732 0.00504639 3.50732 -0.447954 1.50032 1.63305Z"
                        fill="url(#paint0_linear)"
                    />
                    <path
                        d="M11.9984 3.13898C8.36742 3.13898 4.91942 2.81598 3.60242 6.19598C3.05842 7.59198 3.13742 9.40499 3.13742 12.001C3.13742 14.279 3.06442 16.42 3.60242 17.805C4.91642 21.187 8.39242 20.863 11.9964 20.863C15.4734 20.863 19.0584 21.225 20.3914 17.805C20.9364 16.395 20.8564 14.609 20.8564 12.001C20.8564 8.53899 21.0474 6.30398 19.3684 4.62598C17.6684 2.92598 15.3694 3.13898 11.9944 3.13898H11.9984ZM11.2044 4.73598C18.7784 4.72398 19.7424 3.88199 19.2104 15.579C19.0214 19.716 15.8714 19.262 11.9994 19.262C4.93942 19.262 4.73642 19.06 4.73642 11.997C4.73642 4.85198 5.29642 4.73998 11.2044 4.73398V4.73598ZM16.7284 6.20698C16.1414 6.20698 15.6654 6.68298 15.6654 7.26998C15.6654 7.85698 16.1414 8.33298 16.7284 8.33298C17.3154 8.33298 17.7914 7.85698 17.7914 7.26998C17.7914 6.68298 17.3154 6.20698 16.7284 6.20698ZM11.9984 7.44998C9.48542 7.44998 7.44842 9.48799 7.44842 12.001C7.44842 14.514 9.48542 16.551 11.9984 16.551C14.5114 16.551 16.5474 14.514 16.5474 12.001C16.5474 9.48799 14.5114 7.44998 11.9984 7.44998ZM11.9984 9.04698C15.9034 9.04698 15.9084 14.955 11.9984 14.955C8.09442 14.955 8.08842 9.04698 11.9984 9.04698Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="1.54634"
                        y1="22.4671"
                        x2="23.8518"
                        y2="3.16202"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFDD55" />
                        <stop offset="0.5" stopColor="#FF543E" />
                        <stop offset="1" stopColor="#C837AB" />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </a>
    );
    const isLoggedInUser = useSelector(isUserLoggedIn);
    const dispatch = useDispatch();
    const logOutHandler = useCallback(() => dispatch(appActions.logOut()), [dispatch]);
    return (
        <>
            <LoaderSpinner />
            <Layout.Header className={styles.wrap}>
                <div className={styles.item}>
                    <div className="menu__left">
                        <Menu mode="horizontal">
                            <Menu.Item key="1">
                                <Link to="/">
                                    <img
                                        alt={`ОБФ "Доброта и доверие"`}
                                        sizes="(max-width: 768px) 600px, (min-width: 768px) 260px"
                                        src={DDlogoLg}
                                        className="logoDD"
                                        srcSet={`${DDlogoSm} 200w, ${DDlogoSm} 600w`}
                                    />
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div className="menu__center">
                        <Menu
                            mode="horizontal"
                            overflowedIndicator={
                                <div className="menu-btn">
                                    <div className="menu-btn">
                                        <div className="menu-btn__burger" />
                                    </div>
                                </div>
                            }
                        >
                            <Menu.Item key="2">
                                <Link to="/requisites">Реквизиты фонда</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to="/gifts">Дети</Link>
                            </Menu.Item>
                        </Menu>
                    </div>

                    <div className="menu__right">
                        <Menu mode="horizontal">
                            <Menu.Item key="4">
                                <a href="tel:+380972397270">
                                    <strong>+38 097 239 72 70</strong>
                                </a>
                            </Menu.Item>
                            <Menu.Item key="5">{viberLink}</Menu.Item>
                            <Menu.Item key="6">{facebookLink}</Menu.Item>
                            <Menu.Item key="7">{instaLink}</Menu.Item>
                            {isLoggedInUser && (
                                <Menu.Item key="8">
                                    <Link onClick={logOutHandler} to="#">
                                        Logout
                                    </Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    </div>
                </div>
            </Layout.Header>
        </>
    );
};

export default Header;
